import React, { Component } from 'react';
import $ from 'jquery';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from './../Api';

import Swal from 'sweetalert2';


class Usuarios extends Component {
  
  constructor(props){

    super(props); 
    this.state = {users:[],tituloModal:'',botaoModal:''};
    this.getUsers = this.getUsers.bind(this);

    this.novoUsuario = this.novoUsuario.bind(this);
    this.editarUsuario = this.editarUsuario.bind(this);
    this.removerUsuario = this.removerUsuario.bind(this);

  }


  async getUsers(){

    await api.getUsers().then((res) => {

        this.setState({users:res.data});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });

  }


  async saveUser(event){

    event.preventDefault();

    var data = $('#formCadastroUsuario').serialize();
 
    if(this.state.userEditor === ""){

      await api.newUser(data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('O usuário foi inserido.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
    
    }else{

      await api.updateUser(this.state.userEditor,data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('As alterações foram salvas.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
    
    }

    window.$('div').modal('hide');
    this.getUsers();
    
    return false; 

  }


  async removerUsuario(id){


    if(parseInt(window.app.state.userId) === parseInt(id)){ 

      toast.error('Você não pode remover você mesmo.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
      return false; 

    }

 
    Swal.fire({
      title: 'Você tem certeza?', 
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
 
        api.deleteUser(id).then((res) => {

          toast.success('O usuário foi removido.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
          this.getUsers();

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

        });


      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });
 

  }


   

  novoUsuario(){ 
  
    document.getElementById("formCadastroUsuario").reset();

    this.setState({tituloModal:'Novo usuário',botaoModal:'Cadastrar usuário',userEditor:""});
    window.$('#usuarioModal').modal(); 


  }

  editarUsuario(id){ 
  
    document.getElementById("formCadastroUsuario").reset();

    this.setState({tituloModal:'Editar usuário',botaoModal:'Salvar alterações',userEditor:id});
    window.$('#usuarioModal').modal();

    this.state.users.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(id)){

        $.each(item,function(index2,value2){

          console.log(index2+' '+value2);
          window.$('input[name='+index2+']').val(value2);
          window.$('select[name='+index2+']').val(value2);

        });

      }

    });


  }

  componentDidMount(){ this.getUsers(); window.app.loading(false); }
  
  render() {
  
    return (<>

          <link rel='stylesheet' id='mqueries-style-css' href='/css/principal.css' type='text/css' media='all' />

          <div className="section-inner">
            
            <div className="wrapper">
                
              <div className="section-title" style={{marginBottom:'30px'}}>
                  <h2 style={{color:'#fff'}}>Usuários</h2> 

                  <button type="submit" className="btn btn-outline-light" onClick={ () => this.getUsers() }>Atualizar <i className="fas fa-sync-alt"></i></button> <button type="submit" className="btn btn-outline-light" onClick={ () => this.novoUsuario() } >Novo usuário <i className="fas fa-plus"></i></button>

              </div>

              <table className="table table-dark">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Sobrenome</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>

                {

                  this.state.users.map(function(item, i){
                   
                    return(<tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.name}</td>
                      <td>{item.lastName}</td>
                      <td>{item.user}</td>
                      <td>{item.email}</td>
                      <td>
                        
                        <button type="button" className="btn btn-primary btn-sm" onClick={ () => this.editarUsuario(item.id) }><i className="fas fa-user-edit"></i> Editar</button> <button onClick={ () => this.removerUsuario(item.id) } type="button" className="btn btn-danger btn-sm"><i className="far fa-trash-alt"></i> Remover</button>

                      </td>
                    </tr>)

                  }.bind(this))

                }

                </tbody>
              </table>

            </div>

          </div>



 

        <div className="modal fade" id="usuarioModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
        <div className="modal-dialog"  role="document">
            <div className="modal-content">
              
            <br/>
            <center><h3 id="editarUsuario">{this.state.tituloModal}</h3></center>

                <div className="modal-body">
                  
                  <form id="formCadastroUsuario" onSubmit={ (event) => this.saveUser(event) }>
                        
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                              <label >Nome:</label>
                              <input required type="text" name="name" className="form-control"  aria-describedby="emailHelp" /> 
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                              <label >Sobrenome:</label>
                              <input required type="text" name="lastName" className="form-control"  aria-describedby="emailHelp" /> 
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                              <label >Usuário:</label>
                              <input required type="text" name="user" className="form-control"  aria-describedby="emailHelp" /> 
                            </div>
                        </div>
                      <div className="col-md-6">
                        <div className="form-group">
                              <label >E-mail:</label>
                              <input required type="text" name="email" className="form-control"  aria-describedby="emailHelp" /> 
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Senha:</label>
                      <input type="password" name="password" className="form-control" aria-describedby="emailHelp" /> 
                    </div>

                    <div className="form-group">
                      <label>Bloqueado:</label>
                      <select name="blocked" className="form-control">
                        
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                        
                      </select> 
                    </div>

                    <div className="form-group">
                      <label>Admin:</label>
                      <select name="admin" className="form-control">
                        
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                        
                      </select> 
                    </div>

                    <button type="submit" className="btn btn-primary" style={{width:'100%'}} >{this.state.botaoModal}</button>

                  </form>

                </div> 

            </div>
          </div>
          </div>








    </>);

  }


}

export default Usuarios;
