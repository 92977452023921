import React, { Component } from 'react';
import $ from 'jquery'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from './../Api';
import Swal from 'sweetalert2';
import AceEditor from 'react-ace';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';
import 'brace/snippets/html';
import 'brace/ext/language_tools';


const SortableItem = SortableElement(({item,index,state}) => { 
  
  var index2 = state.blocks.map(function(x) {return x.id; }).indexOf(item);
  var dados = state.blocks[index2]; 

  return(<div key={'aaa_'+index} className="col-md-12 row div_edit_block_modal">
    <div className="col-md-4">ID: {dados.id}</div>
    <div className="col-md-8" >{dados.name}</div>
  </div>) 
  
});

const SortableList = SortableContainer(({items,state}) => {

  var s = 'calc('+window.$('body').height()+'px - 206px)';

  //var index  = state.blocks.map(function(x) {return x.id; }).indexOf(id_procurar);
 // console.log(state);

  return (
 
    <div style={{ overflow:'auto',height:s,width:'100%'}}>
      {
        
        items.map((value, index) => {

            return(<SortableItem key={`item-${value}`} index={index} item={value} state={state} />)

          }
          
        )
      
      }
    </div>
    
  );
});


class Blockly_secao extends Component {
  
  constructor(props){

    super(props); 
    this.state = {blocks:[],secoes:[],blockSequence:[],tituloModal:'',botaoModal:''};
    this.getBlocklySection = this.getBlocklySection.bind(this);

    this.novoBlocklySection = this.novoBlocklySection.bind(this);
    this.editarBlocklySection = this.editarBlocklySection.bind(this);
    this.removerBlocklySection = this.removerBlocklySection.bind(this);
    this.editarBlocklySectionBlock = this.editarBlocklySectionBlock.bind(this);
    this.saveBlocklySectionBlock = this.saveBlocklySectionBlock.bind(this);
    this.editarBlocklySectionBlockSequence = this.editarBlocklySectionBlockSequence.bind(this);

    window.Blockly_secao = this;

  }


  onSortEnd = async ({oldIndex, newIndex}) => {

    await this.setState(({blockSequence}) => ({
      blockSequence: arrayMove(blockSequence, oldIndex, newIndex),
    }));

    

   


    await api.changeSequenceBlocksSection(this.state.blockSequence,this.state.sectionEditor).then((res) => {

      this.getBlocklySection();

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
    });
    
  };

  async getBlocklySection(){

    await api.getBlocklySection().then((res) => {

        this.setState({secoes:res.data});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });

  }

  async getBlocklyBlocks(){

    await api.getAllJsBlock().then((res) => {

        this.setState({blocks:res.data});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });

  }



   


  async saveBlocklySection(event){

    event.preventDefault();

    $("#manual_xml").val(this.refs.aceEditor.editor.getValue());

    var data = $('#formCadastrosection').serialize();
 
    if(this.state.sectionEditor === ""){

      await api.newBlocklySection(data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('A categoria foi inserida.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
    
    }else{

      await api.updateBlocklySection(this.state.sectionEditor,data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('As alterações foram salvas.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
    
    }

    window.$('div').modal('hide');
    this.getBlocklySection();
    this.getBlocklyBlocks();
    
    return false; 


  }

  async removerBlocklySection(id){
 
    Swal.fire({
      title: 'Você tem certeza?', 
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
 
        api.deleteBlocklySection(id).then((res) => {

          toast.success('A categoria foi removida.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
          this.getBlocklySection();

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

        });


      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });
 

  }


   

  novoBlocklySection(){ 
    
    this.refs.aceEditor.editor.setValue('',-1);

    document.getElementById("formCadastrosection").reset();

    this.setState({tituloModal:'Nova categoria',botaoModal:'Cadastrar categoria',sectionEditor:""});
    window.$('#sectionModal').modal();

  }



  editarBlocklySection(id){ 
  
    document.getElementById("formCadastrosection").reset();

    this.setState({tituloModal:'Editar categoria',botaoModal:'Salvar alterações',sectionEditor:id});
    window.$('#sectionModal').modal();

    this.state.secoes.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(id)){

        $.each(item,function(index2,value2){

          console.log(index2+' '+value2);
          window.$('input[name='+index2+']').val(value2);
          window.$('select[name='+index2+']').val(value2);
          window.$('textarea[name='+index2+']').val(value2);

        });

        console.log(item.manual_xml);
        setTimeout(function(){ this.refs.aceEditor.editor.setValue(item.manual_xml,-1); }.bind(this), 10);
         

      }

    }.bind(this));


  }


  editarBlocklySectionBlock(id){


    $('.checkSelectImport44').prop('checked', false);

    window.$('#sectionBlockModal').modal(); 

    this.state.secoes.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(id)){

        this.setState({tituloModal:'Blocos da seção '+item.name,botaoModal:'Salvar alterações',sectionEditor:item.id});

        item.blocks.forEach(function(item2,index2,array2){

          $('.checkSelectImport44[idc='+item2+']').prop('checked', true);

        });

      }

    }.bind(this));

  }

  async saveBlocklySectionBlock(event){

    event.preventDefault();

    var blocks = [];

    window.$(".checkSelectImport44:checked").each(function(){

      blocks.push($(this).val())

    });

    console.log(blocks);



    await api.salvarSecaoBlocks(this.state.sectionEditor,blocks).then((res) => {

      console.log(res.data);
      return true;

    }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        return false;

    });

    window.$('div').modal('hide');

    this.getBlocklyBlocks();
    this.getBlocklySection();
    
  }


  //Para editar a squencia dos blocos
  editarBlocklySectionBlockSequence(id){

    window.$("#sectionBlockModalSetSequence").modal();

    this.state.secoes.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(id)){

        console.log(item);
        
        this.setState({tituloModal:'Blocos da seção '+item.name,botaoModal:'Salvar alterações',sectionEditor:item.id,blockSequence:item.blocks});

        item.blocks.forEach(function(item2,index2,array2){

          $('.checkSelectImport44[idc='+item2+']').prop('checked', true);

        });

      }

    }.bind(this));

  }


  componentDidMount(){ this.getBlocklySection(); this.getBlocklyBlocks(); window.app.loading(false); }
  
  render() {
  
    return (<>

          <link rel='stylesheet' id='mqueries-style-css' href='/css/principal.css' type='text/css' media='all' />

          <div class="section-inner">
            
            <div class="wrapper">
                
              <div class="section-title" style={{marginBottom:'30px'}}>
                  <h2 style={{color:'#fff'}}>Blockly categoria</h2> 
                  <button type="submit" class="btn btn-outline-light" onClick={ () => this.getBlocklySection() }>Atualizar <i class="fas fa-sync-alt"></i></button> <button type="submit" class="btn btn-outline-light" onClick={ () => this.novoBlocklySection() } >Nova categoria <i class="fas fa-plus"></i></button>
              </div>

              <table class="table table-dark">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Colour</th>
                    <th scope="col">SecondaryColour</th>
                    <th scope="col">TertiaryColour</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>

                {
 
                  this.state.secoes.map(function(item, i){
                     
                    
                    return(<tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.name}</td>
                      <td>{item.description}</td>

                      <td style={{backgroundColor:item.colour,fontWeight:"700"}}>{item.colour}</td>
                      <td style={{backgroundColor:item.secondaryColour,fontWeight:"700"}}>{item.secondaryColour}</td>
                      <td style={{backgroundColor:item.tertiaryColour,fontWeight:"700"}}>{item.tertiaryColour}</td>

                      <td>
                        
                        <button type="button" class="btn btn-primary btn-sm" onClick={ () => this.editarBlocklySection(item.id) }><i class="fas fa-pencil-alt"></i> Editar</button> 
                        <font> </font>
                        <button type="button" class="btn btn-primary btn-sm" onClick={ () => this.editarBlocklySectionBlock(item.id) }><i class="fas fa-pencil-alt"></i> Blocos</button>
                        <font> </font>
                        <button type="button" class="btn btn-primary btn-sm" onClick={ () => this.editarBlocklySectionBlockSequence(item.id) }><i class="fas fa-pencil-alt"></i> Sequencia dos blocos</button>
                        <font> </font> 
                        <button onClick={ () => this.removerBlocklySection(item.id) } type="button" class="btn btn-danger btn-sm"><i class="far fa-trash-alt"></i> Remover</button>

                      </td>

                    </tr>)

                  }.bind(this))

                }

                </tbody>
              </table>

            </div>

          </div>



 

        <div className="modal  " id="sectionModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
          <div className="modal-dialog"  role="document">
              <div className="modal-content">
                
              <br/>
              <center><h3 id="editarsection">{this.state.tituloModal}</h3></center>

                  <div className="modal-body">
                    
                    <form id="formCadastrosection" onSubmit={ (event) => this.saveBlocklySection(event) }>
                          
                      <div class="row">

                        <div class="col-md-6">
                          <div className="form-group">
                                <label>Nome:</label>
                                <input required type="text" name="name" className="form-control"  aria-describedby="emailHelp" /> 
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div className="form-group">
                                <label >Descrição:</label>
                                <textarea type="text" name="description" className="form-control" aria-describedby="emailHelp"></textarea>
                          </div>
                        </div>

                      </div>

                      <div class="row">

                        <div class="col-md-4">
                          <div className="form-group">
                                <label>Colour:</label>
                                <input required type="text" name="colour" className="form-control"  aria-describedby="emailHelp" /> 
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div className="form-group">
                                <label >SecondaryColour:</label>
                                <input type="text" name="secondaryColour" className="form-control" aria-describedby="emailHelp"/>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div className="form-group">
                                <label >TertiaryColour:</label>
                                <input type="text" name="tertiaryColour" className="form-control" aria-describedby="emailHelp"/>
                          </div>
                        </div>

                      </div>

                      <div class="row" hidden>

                        <div class="col-md-12">
                          <div className="form-group">
                                <label >Manual XML:</label>
                                <AceEditor
                                  mode="javascript"
                                  ref="aceEditor"
                                  theme="monokai" 
                                  name="aceEditor"
                                  editorProps={{$blockScrolling: true}}
                                  width="auto"
                                  height={200}
                                  showPrintMargin={false}

                                />
                          </div>
                        </div>

                      </div>
                       


                       
                      <textarea hidden type="text" name="manual_xml" id="manual_xml" className="form-control" aria-describedby="emailHelp"></textarea>

                      <button type="submit" className="btn btn-primary" style={{width:'100%'}} >{this.state.botaoModal}</button>

                    </form>

                  </div> 

              </div>
            </div>
          </div>




          <div className="modal  " id="sectionBlockModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
          <div className="modal-dialog"  role="document">
              <div className="modal-content">
                
              <br/>
              <center><h3 id="editarsection">{this.state.tituloModal}</h3></center>

                  <div className="modal-body">

                    <form id="formCadastrosection" onSubmit={ (event) => this.saveBlocklySectionBlock(event) }>
                      <div class="row">
        
                        { 

                          this.state.blocks.map(function(item, i){
                           
                            var id = "id_c"+item.id;

                            return(

                                <div key={item.id} class="col-md-12" style={{display:"flex",marginBottom:"5px"}}>
                                  <input class="checkSelectImport44 checkBoxScene" type="checkbox" id={id} idc={item.id} value={item.id} />
                                  <label htmlFor={id} style={{lineHeight:'11px',marginLeft:'17px'}}>{item.name} - {item.description}</label>
                                </div>

                            )

                          })

                        }
                      </div>
                       <button type="submit" className="btn btn-primary" style={{width:'100%'}} >{this.state.botaoModal}</button>

                    </form>

                  </div> 

              </div>
            </div>
          </div>


          <div className="modal" id="sectionBlockModalSetSequence" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
            <div className="modal-dialog"  role="document">
              <div className="modal-content">
                
              <br/>
              <center><h3 id="editarsection">{this.state.tituloModal}</h3></center>

                  <div className="modal-body">

                    <form id="formCadastrosection" onSubmit={ (event) => this.saveBlocklySectionBlock(event) }>
                      <div class="row">
                      {

                        <SortableList state={this.state} items={this.state.blockSequence} onSortEnd={this.onSortEnd} />

                      }
                      </div> 
                    </form>

                  </div> 

              </div>
            </div>
          </div>


          <style>{' .checkBoxScene{ opacity: 1 !important; } '}</style>


    </>);

  }


}

export default Blockly_secao;
