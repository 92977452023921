import React, { Component } from 'react';
import{ Link } from 'react-router-dom';
import $ from 'jquery';
import api from './Api';
import Swal from 'sweetalert2';
import Blockly from './studio/Blockly';

import { BounceLoader } from "react-spinners";
import { ControlledEditor } from '@monaco-editor/react';

import './studio/css/studio.css';
import './studio/css/tabs.css';
import './studio/css/assets.css';
import './studio/css/log.css'; 
import './studio/css/customConfigAcer.css'; 

import Tooltip  from 'rc-tooltip';
import { toast } from 'react-toastify';

var QRCode = require('qrcode.react');
var resizecheck;

require('dotenv').config();

class Studio extends Component {
  

  constructor(props){

    super(props);
    
    this.state = {
      debug:0,
      hiddenHeader:0,
      hiddenFiles:0,
      hiddenAssets:0,
      hiddenConsole:0,
      hiddenBarFiles:0,
      description:null,
      defaultCanvasWidth:null,
      defaultCanvasHeigth:null,
      defaultFilesCreate:{},
      hiddenCanvasSize:0,
      getOptions:0,
      mostraResto:0,
      hiddenResizeCanvas:0,
      hiddenTrash:0,
      hiddenCloseTab:0,
      data:{},
      typeShow:1,
      url_master:"/",
      showcode:"",
      showcodelog:"",
      scala:1.0};

    this.resetWorksPace = this.resetWorksPace.bind(this); 
    this.externalLink = this.externalLink.bind(this);
    this.deleteGame = this.deleteGame.bind(this);
    this.getDataExpo = this.getDataExpo.bind(this);
    this.restartExpo = this.restartExpo.bind(this);
    this.showLog = this.showLog.bind(this);
    this.reinstallModules = this.reinstallModules.bind(this);
    this.reinstallAll = this.reinstallAll.bind(this);

    window.studio = this;

  }

  reinstallAll(){

    window.$("div").modal("hide");

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Todos os arquivos do projeto serão removidos.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
        
        window.$("div").modal("hide");
        toast.success('O projeto está sendo reeinstalado.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
           

        $("#loadingTxt").html('Reinstalando projeto.');
        window.app.loading(true);

        setTimeout(function(){ this.refreshIframe(); }.bind(this), 1000);
        setTimeout(function(){ this.refreshIframe(); }.bind(this), 2000);
        setTimeout(function(){ this.refreshIframe(); }.bind(this), 3000);
        setTimeout(function(){ this.refreshIframe(); }.bind(this), 4000);
        setTimeout(function(){ this.refreshIframe(); }.bind(this), 5000);
        
        api.reinstallProject(this.props.match.params.game).then((res) => {
      
          window.$("div").modal("hide"); 
           
          setTimeout(function(){ 

            window.app.loading(false);
            window.studio_blockly.getTreeFolder(true);

           }.bind(this), 10000);

        }).catch(error => {
      
            console.log(error.data);
            console.log(error);
            console.log(error.response);
      
        });

      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });

  }

  reinstallModules(){
    
    window.$("div").modal("hide");
    toast.success('Os módulos estão sendo reeinstalados.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
       
    setTimeout(function(){ this.refreshIframe(); }.bind(this), 1000);
    setTimeout(function(){ this.refreshIframe(); }.bind(this), 2000);
    setTimeout(function(){ this.refreshIframe(); }.bind(this), 3000);
    setTimeout(function(){ this.refreshIframe(); }.bind(this), 4000);
    setTimeout(function(){ this.refreshIframe(); }.bind(this), 5000);
    
    api.reinstallModules(this.props.match.params.game).then((res) => {
  
      window.$("div").modal("hide");
      toast.success('Os módulos estão sendo reeinstalados.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
        
    }).catch(error => {
  
        console.log(error.data);
        console.log(error);
        console.log(error.response);
  
    });

  }


  //para mostar o canvas do phaser
  showCanvas(){ $('.optionHeaderStudio').removeClass('ativo');$('.optionHeaderStudio[type="showCanvas"]').addClass('ativo');$('#divPhaser').fadeIn(0); $("#divAcerEditor").fadeOut(0); }

 

  //para resetar o tamanho da workspace
  resetWorksPace(){ 
    
  }

  getDataExpo(){

    setTimeout(function(){ 
      
      if(window.$(".blocklyWorkspace").length === 0){ 

        return false;

      }
       
      this.getDataExpo(); 
      window.studio_blockly.resizeParts();
      window.Blockly.svgResize(window.demoWorkspace);

    }.bind(this), 3000);
 
    if(this.state.data === undefined){ return false; }

    api.getDataExpo1(this.state.data.user,this.props.match.params.game).then((res) => {
 
      this.setState({dataExpo1:res.data});
      
  }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

  });

  api.getStatus(this.props.match.params.game).then((res) => {
 
    this.setState({statusProject:res.data.status});
      
  }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

  });

 

  }


  //Para reiniciar o expo
  restartExpo(){
 
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'O projeto pode levar cerca de 10 segundos a 3 minutos para voltar.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
   
        //remove o arquivo na api
        api.restartExpo(this.props.match.params.game).then((res) => {
          
          toast.success('O projeto foi reiniciado.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
          
          this.refreshIframe();
          setTimeout(function(){ this.refreshIframe(); }.bind(this), 1000);
          setTimeout(function(){ this.refreshIframe(); }.bind(this), 2000);
          setTimeout(function(){ this.refreshIframe(); }.bind(this), 3000);
          setTimeout(function(){ this.refreshIframe(); }.bind(this), 4000);
          setTimeout(function(){ this.refreshIframe(); }.bind(this), 5000);

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
      
        });

        console.log('restartado');

      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });

  }
 

  componentDidMount(){

    delete window.studio_blockly;
    
    this.getDataExpo();

    $("#loadingTxt").html('Carregando studio');

    //caso esteja informado opcao, busca os dados dela na api
    if(this.props.match.params.options === undefined){

        //console.log('deu undefined');
        this.setState({mostraResto:true});

    }else{

      api.getStudioOptions(this.props.match.params.options).then((res) => {

          res.data.mostraResto = true;
          this.setState(res.data);
          
      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });
    
    }
    
    resizecheck = setInterval(() => { 

      if(window.studio_blockly !== undefined){ window.studio_blockly.resizeParts(); }
      if(window.Blockly !== undefined){ window.Blockly.svgResize(window.demoWorkspace); }
      
    },2000)

  }

  componentWillUnmount(){

    clearInterval(resizecheck);

  }

  //para delar o game
  deleteGame(){
 
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Depois de excluir é impossível recuperar.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
   
        //remove o arquivo na api
        api.deleteGame(this.props.match.params.game).then((res) => {
          
          toast.success('O projeto foi removido.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
          this.props.history.push('/');

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
      
        });

        console.log('removido');

      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });

  }

  resetWidth(){

		var largura = $(window).width(); 
    var b = largura * 0.7;
    $("#resizeBlockly").css('width',b+'px');
    
    setTimeout(function(){

      window.studio_blockly.saveSettings();

    }, 100);

  }
  
  externalLink(){

    window.open(sessionStorage.getItem('backend')+'/uploads/users/'+this.state.data.user+'/'+this.state.data.id+'/index.html', '_blank');

  }
  
  refreshIframe(){

    var url = $("#iframeTp").attr('src');
    $("#iframeTp").attr('src','');
    $("#iframeTp").attr('src',url);

  }


  showLog(){

    window.$("#showJavascriptModal").modal();
    api.getLog(this.props.match.params.game).then((res) => {
          
      this.setState({showcodelog:res.data});

    }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
  
    });

  }

  render(){

    var Hei = window.innerHeight;
    Hei = Hei - 60;
 
    var showhelp = false;
    var link_expo = undefined;
    var qq = window.parseQueryString();

    if(qq.video !== undefined && qq.vh !== undefined && qq.vw !== undefined){ showhelp = true; }

    if(this.state.dataExpo1 !== undefined){
      
      if(this.state.dataExpo1.expoServerNgrokUrl !== undefined){
        
        try{
          
          var link_expo2 = this.state.dataExpo1.expoServerNgrokUrl; 

          if(typeof link_expo2 === "string"){
            
            link_expo2 = link_expo2.replace("https://","exp://");
            link_expo2 = link_expo2.replace(".direct",".direct:80");
            link_expo = link_expo2;

          }
          
          
        }catch(error){
          
          console.log(error);

        }
        
      }
       
    }

    var obs = window.parseQueryString();

    return (<React.Fragment>
          
          {
          
            (obs.ops !== undefined && obs.ops !== 'disable') &&
            <style>{'.studio_options{ display:none; }'}</style>

          }
          <style>{' body{ overflow: hidden; }  .iframeAsset{ height: '+Hei+'px; } '}</style>

          <link rel='stylesheet' href='/css/studio.css' type='text/css' media='all' />
  
          <div id="pageStudio" style={{display:'flex'}}>

            <div className="studio_options">

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Voltar a página inicial</span>}>
              <div className="option option_1 hvr-hang">   <Link to="/"><img alt="" src="/media/home_user_places.png" /></Link> </div>
              </Tooltip> 

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Configurações do projeto</span>}>
                <div onClick={ () => window.$("#editaProjetoModal").modal() } className="option option_3 hvr-hang"  > <img alt="" src="/media/builder_png_px_website.png" /> </div>
              </Tooltip>

              {

                (window.parseQueryString().qrcode !== undefined) &&
                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Abrir no celular</span>}>
                  <div onClick={ () => { 

                    this.setState({showqr:false});
                    setTimeout(function(){ this.setState({showqr:true}); }.bind(this), 100);
                    setTimeout(function(){ window.$("#expotDataModal").modal(); }.bind(this), 200);
                    
                  }} className="option option_3 hvr-hang"  > <img alt="" src="/media/expo.png" /> </div>
                </Tooltip>

              }
              


               
              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Resetar largura da workspace</span>}>
                <div onClick={ () => this.resetWidth() } className="option option_3 hvr-hang"> <img alt="" src="/media/synchronize_sync_refresh.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Abrir em nova aba</span>}>
                <div onClick={ () => this.externalLink() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/tab_new_add_plus.png" /> </div>
              </Tooltip>
      
              <font id="buttonAssociaFile" style={{display:'none'}}>
                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Associção de arquivo</span>}>
                  <div onClick={ () => window.AssocitionModal.openAssocitionModal() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/seo-web-code-icon.png" /> </div>
                </Tooltip>
              </font>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Deletar projeto</span>}>
                <div onClick={ () => this.deleteGame() } style={{display:'none'}} className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/trashcan_empty_alt.png" /> </div>
              </Tooltip>
              
            </div>

            <div className="studio_2" style={{display:'flex'}}>

              <div id="divBlockly">

                  { 
                    (this.state.mostraResto) &&
                    <Blockly resetWidth={ () => this.resetWidth() } state={this.state} setState={(data) => this.setState(data) } {...this.props} /> 
                  }

              </div>

            </div>
 
            <div style={{backgroundColor: '#282f34',marginLeft:'18px',width:'100%'}}>

              <div className="headerLeftStudio">
                             
                  <div style={{textAlign:'left',paddingLeft:'15px',cursor:'auto'}} className="optionHeaderStudio ativo" type="showCanvas" onClick={ () => this.showCanvas() }>

                    <font>PREVIEW</font>
                    <font style={{marginLeft:"15px"}}>
                      
                      {
                        
                        //0 = stopado
                        //1 = Iniciando
                        //2 = Online
                        //3 = Na fila
                        //4 = fatal error
                        //5 = Reinstalando modulos

                        (this.state.statusProject === 0) &&
                        <font style={{color:'#ec0057'}}><font style={{color:'#ec0057',fontSize:'25px',position:'relative',top:'4px'}}>•</font> Desligado</font>

                      }

                      {
                        
                        (this.state.statusProject === 1) &&
                        <font style={{color:'#80bb92'}}><font style={{color:'#80bb92',fontSize:'25px',position:'relative',top:'4px'}}>•</font> Iniciando</font>

                      }

                      {
                        
                        (this.state.statusProject === 2) &&
                        <font style={{color:'#25ef00'}}><font style={{color:'#25ef00',fontSize:'25px',position:'relative',top:'4px'}}>•</font> Online</font>

                      }

                      {
                        
                        (this.state.statusProject === 3) &&
                        <font style={{color:'#65b8ff'}}><font style={{color:'#65b8ff',fontSize:'25px',position:'relative',top:'4px'}}>•</font> Na fila</font>

                      }

                      {
                        
                        (this.state.statusProject === 4) &&
                        <font style={{color:'#ff0000'}}><font style={{color:'#ff0000',fontSize:'25px',position:'relative',top:'4px'}}>•</font> Erro fatal</font>

                      }

                      {
                        
                        (this.state.statusProject === 5) &&
                        <font style={{color:'#80bb92'}}><font style={{color:'#80bb92',fontSize:'25px',position:'relative',top:'4px'}}>•</font> Reinstalando</font>

                      }

                    </font>

                  </div>

                  {

                    (showhelp === true) &&
                    <button onClick={() => window.studio_blockly.setState({showVideo:true}) } className="btn btn-danger btn-sm flash t2 midix infinite" style={{
                        position:'absolute',
                        left:'calc(100% - 340px)',
                        height:'21px',
                        paddingTop:'1px',
                        fontSize:'11px',
                        top:'5px'}}
                    ><i className="fas fa-mitten"></i> Ajuda</button>

                  }
                  
                  <button onClick={() => this.showLog() } className="btn btn-secondary  btn-sm flash t2 midix infinite" style={{position:'absolute',left:'calc(100% - 289px)',height:'21px',paddingTop:'1px',fontSize:'11px',top:'5px'}}><i className="fas fa-file-medical"></i> Logs</button>
                  <button onClick={() => this.restartExpo() } className="btn btn-danger btn-sm flash t2 midix infinite" style={{position:'absolute',left:'calc(100% - 227px)',height:'21px',paddingTop:'1px',fontSize:'11px',top:'5px'}}><i className="fas fa-file-medical"></i> Reiniciar Expo</button>
                  <button onClick={() => window.$("#reinstalar").modal() } className="btn btn-danger btn-sm flash t2 midix infinite" style={{position:'absolute',left:'calc(100% - 112px)',height:'21px',paddingTop:'1px',fontSize:'11px',top:'5px'}}><i class="fas fa-retweet"></i> Reinstalar</button>

                  
                  <label hidden className="containerCheck" style={{left:'calc(100% - 140px)'}}><font style={{color:"#fff"}}>Autosave (5s)</font>
                    <input onChange={ () => window.studio_blockly.saveSettings() } id="input_autosave" type="checkbox" />
                    <span className="checkmark"></span>
                  </label>

              </div>

              <div style={{display:'flex'}}>
                <div style={{position:'absolute'}}><i onClick={ () => this.refreshIframe() } className="fas fa-sync-alt" style={{marginLeft:'10px',marginRight:'10px',color:'#fff',cursor:'pointer'}}></i></div>
                <div style={{width:'100%'}}>
                  <input id="input_url" className="input_change_link" onChange={ async (event) => {

                    await this.setState({url_master:event.target.value});
                    this.refreshIframe();

                  }} value={this.state.url_master} />
                </div>
              </div>
              
              <div>
                <div id="divPhaser">

                <font style={{position:'absolute',color:'#fff',fontFamily:'inherit',top:'65px',marginLeft:'13px'}}>Usuário:<strong> {this.state.data.user}</strong> Projeto: <strong>{this.state.data.id}</strong></font>

                  <br/>
                  <center>

                    <button onClick={ () => { window.studio_blockly.build(); }} style={{backgroundColor:'#dddddd',color:'#535353',zIndex:'999',marginRight:'5px'}} className="btn btn-success btn-sm" ><i className="far fa-play-circle"></i> Build</button>

                    <button onClick={ () => { if(this.state.scala <= 0.3){ return false; } this.setState({scala: (this.state.scala - 0.1) }); }} style={{zIndex:'999'}} className="btn btn-success btn-sm" ><i className="fas fa-search-minus"></i></button>
                    <button onClick={ () => { if(this.state.scala >= 1){ return false; } this.setState({scala: (this.state.scala + 0.1) }); }} style={{zIndex:'999',marginLeft:"4px"}} className="btn btn-success btn-sm" ><i className="fas fa-search-plus"></i></button>

                  </center>

                  <div style={{paddingBottom:'5px',position:'relative',zIndex:'9999'}}>
                      <center>
                        {

                          (this.state.typeShow === 1) &&
                          <React.Fragment>
                            <button onClick={ () => { this.setState({typeShow:1}); }} className="bt_treefold btn btn-sm btn-primary" ><i className="fas fa-laptop-code"></i> Preview</button>
                            <button onClick={ () => { this.setState({typeShow:2}); }} style={{marginLeft:"4px"}} className="bt_treefold btn btn-secondary btn-sm" ><i className="fab fa-js-square"></i> Code view</button>
                            <button onClick={ () => { this.setState({typeShow:3}); }} style={{marginLeft:"4px"}} className="bt_treefold btn btn-secondary btn-sm" ><i className="far fa-folder"></i> Arquivos</button>
                          </React.Fragment>

                        }
                        {

                          (this.state.typeShow === 2) &&
                          <React.Fragment>
                            <button onClick={ () => { this.setState({typeShow:1}); }} className="bt_treefold btn btn-sm btn-secondary" ><i className="fas fa-laptop-code"></i> Preview</button>
                            <button onClick={ () => { this.setState({typeShow:2}); }} style={{marginLeft:"4px"}} className="bt_treefold btn btn-primary btn-sm" ><i className="fab fa-js-square"></i> Code view</button>
                            <button onClick={ () => { this.setState({typeShow:3}); }} style={{marginLeft:"4px"}} className="bt_treefold btn btn-secondary btn-sm" ><i className="far fa-folder"></i> Arquivos</button>
                          </React.Fragment>

                        }

                        {

                          (this.state.typeShow === 3) &&
                          <React.Fragment>
                            <button onClick={ () => { this.setState({typeShow:1}); }} className="bt_treefold btn btn-sm btn-secondary" ><i className="fas fa-laptop-code"></i> Preview</button>
                            <button onClick={ () => { this.setState({typeShow:2}); }} style={{marginLeft:"4px"}} className="bt_treefold btn btn-secondary btn-sm" ><i className="fab fa-js-square"></i> Code view</button>
                            <button onClick={ () => { this.setState({typeShow:3}); }} style={{marginLeft:"4px"}} className="bt_treefold btn btn-primary btn-sm" > <i className="far fa-folder"></i> Arquivos</button>
                          </React.Fragment>

                        }
                      </center> 
                  </div>

                  <div>

                  {

                    (this.state.typeShow === 1) &&
                    <React.Fragment>

                      {
                        (this.state.dataExpo1 !== undefined) &&
                        <React.Fragment>
                          <div className="celular" style={{marginTop:'-'+((this.state.scala*190) - 190)+'px',transform:'scale('+this.state.scala+')'}}>
                            <img src="/media/IPhone_11_Pro_Max_Midnight_Green.svg" />
                            
                            <div hidden className="iframeTp" style={{backgroundColor:'#fff'}} >
                              <div style={{margin:'0 auto',width:'80px',marginTop:'300px'}}>
                                <BounceLoader size={80} color={"#123abc"} loading={true}/>
                              </div>
                            </div>

                            <iframe style={{backgroundColor:"#fff"}} className="iframeTp" id="iframeTp" src={window.location.protocol+'//'+window.location.hostname+":"+(parseInt(this.props.match.params.game)+25000)}></iframe>
                          </div>
                        </React.Fragment>

                      }

                    </React.Fragment>

    
                  }
                  {

                  (this.state.typeShow === 2) &&
                    <React.Fragment>
                      <div>
                        <style>{'.celular{ padding: 0px; width: 100%; position: relative; top: -22px; }'}</style>
                        <ControlledEditor 
                           
                          height="80vh"
                          language='javascript'
                          value={this.state.showcode}
                          //onChange={}
                          theme="dark"
                          options={{
                            fontSize:'14',
                            readOnly: true
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }

                  {

                    (this.state.typeShow === 3) &&
                    <React.Fragment><style>{' .treefolder{ pointer-events: all !important; opacity:1 !important; display:block !important; } '}</style> </React.Fragment>

                  }

                  <style>{' .treefolder{top: 42px; bottom: 0px; opacity:0; } '}</style>
                     
                  </div>
                </div> 
              </div>
            </div>

        </div>



        <div className="modal fade" id="expotDataModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog" role="document" >
              <div className="modal-content">

                <center>
                  <img style={{height:'70px',marginTop:'50px',marginBottom:'25px'}} src="/media/1_OwTgC3_fZg3lUg7Nc17F8Q.png" />
                </center>

                <center style={{fontSize:'22px',padding:'20px'}}>Mire o QR code abaixo usando o aplicativo <strong>EXPO</strong> do seu celular.</center>

                {

                  (link_expo !== undefined && this.state.showqr === true) &&
                  <React.Fragment>

                    <center style={{ marginBottom:'35px',marginTop:'25px'}}>
                      <QRCode size={256} value={link_expo} />
                    </center>

                  </React.Fragment>

                }
                
                <center>
                <button style={{marginBottom:'15px'}} onClick={ () => window.$('div').modal('hide') } type="button" className="btn btn-secondary">Fechar</button>
                </center>
                 
              </div>
          </div>
      </div>



      <div className="modal fade" id="showJavascriptModal" style={{zIndex:'9999'}} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog" role="document" style={{maxWidth:'calc(100% - 40px)'}}>
              <div className="modal-content">

              <ControlledEditor 

                height="95vh"
                language='javascript'
                value={this.state.showcodelog}
                theme="dark"
                ref="editor_control"
                options={{fontSize:'14',readOnly: true}}

              />
                 
              </div>
          </div>
      </div>


      <div className="modal fade" id="reinstalar" style={{zIndex:'9999'}} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog" role="document" >
              <div className="modal-content">

                <center><h3 style={{marginTop:'40px'}}>O que deseja reinstalar?</h3></center>


                <div style={{padding:"50px",textAlign:'center'}}>
                  <button onClick={ () => this.reinstallModules() } class="btn btn-primary" style={{width:"100%"}}>Somente módulos</button>
                  <br/>
                    <button onClick={ () => this.reinstallAll() } class="btn btn-danger" style={{width:"100%",marginTop:"30px"}}> <i class="fas fa-skull-crossbones"></i> RESETAR PROJETO <i class="fas fa-skull-crossbones"></i></button>
                    Essa opção irá remover todos os arquivos. 
                   
                    <center>
                      <button onClick={() => window.$('div').modal('hide')} class="btn btn-secondary" style={{width:"100%",marginTop:"30px"}}>Cancelar e fechar</button>
                    </center> 

                </div>
              </div>
          </div>
      </div>


       
      </React.Fragment>)


  }


}

export default Studio;