import React, { Component } from 'react';
import $ from 'jquery';
import api from './../Api';
import moment from 'moment'; 
import { ClipLoader } from "react-spinners";

class ListaProjetos extends Component {

	constructor(props){ 

		super(props);
		this.acessarGame = this.acessarGame.bind(this);

		this.state = {postProjeto:false};

		window.listaprojetos = this;

	}

	acessarGame(id){
	 
	    window.app.loading(true);
	    setTimeout(function(){ 
			
			this.props.history.push('/studio/'+id);

		}.bind(this), 500);  

  	} 
 
  

  cadastraGame(event){
	 
	event.preventDefault();
	if(this.state.postProjeto === true){ return false; }
	
	this.setState({postProjeto:true});
	
    var dados = {"name": $("#exampleInputEmail11").val(),
    "dateCreate":moment().format("YYYY-MM-DD h:mm:ss"),
    "user": sessionStorage.getItem('userId'),
    fontSize:16,
    widthcanvas:4000,
	description:$("#exampleInputEmail22").val(),
	autosave:'1',
	realtime:'0',
	treecache:JSON.stringify({}),
	tabcache:JSON.stringify([]),
	};
  
    api.newGames(dados).then((res) => {

      console.log(res);
      window.$('div').modal('hide');
      setTimeout(function(){ this.acessarGame(res.data.id); }.bind(this), 200);
      
    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

    });

  }


	render(){

		return(<div className="modal fade" id="novoProjetoModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				    <br/>
			    	<center><h3>Novo projeto?</h3></center>
				      	<div className="modal-body">
					        
					        <form id="formCadastroGame" onSubmit={ (event) => this.cadastraGame(event) }>
		                    
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail1">Nome do projeto:</label>
			                      <input required type="text" name="name" className="form-control" id="exampleInputEmail11" aria-describedby="emailHelp" /> 
			                    </div>

			                    <div className="form-group" hidden>
									<label >Editor padrão:</label>

									<select id="defaultEditor2" className="form-control">

										{ 	

											window.defaultEditorOptions.map(function(item, i){
											  
											  return <option key={i} value={i} >{item}</option>

											})

										}

									</select>
								</div>

			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail2">Descrição:</label>
			                      <textarea type="text" name="name" className="form-control" id="exampleInputEmail22" aria-describedby="emailHelp"></textarea>
			                    </div>

			        
			                    <button type="submit" className="btn btn-primary" style={{width:'100%'}} >
									
									{

										(this.state.postProjeto === true) &&
										<React.Fragment>
											<center><ClipLoader
											 
												size={20}
												//size={"150px"} this also works
												color={"#fff"}
												loading={true}
											/></center>
										</React.Fragment>

									}

									{

										(this.state.postProjeto === false) &&
										<React.Fragment>Cadastrar projeto <i className="fas fa-plus"></i></React.Fragment>

									}
									
								
								
								</button>

			                </form>

				      	</div> 

				    </div>
				  </div>
			  	</div>) 

	}


}

export default ListaProjetos;