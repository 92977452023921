import React, { Component } from 'react';
//import $ from 'jquery';
import{ BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';

import ListaGames from './ListaGames'; 
import Studio from './Studio'; 
import Header from './Header';
import Help from './Help';
import Configuracao from './Configuracao';

import Usuarios from './pages/Usuarios';

import Blockly_blocks from './pages/Blockly_blocks';
import Blockly_categoria from './pages/Blockly_categoria';
import Blockly_secao from './pages/Blockly_secao';

import io from 'socket.io-client';

class Principal extends Component {
  

  constructor(props){

    super(props); 

    window.principal = this;
    
  }


  componentDidMount(){

    window.socket = io('/socket.io');

    window.socket.on('connect',function(){ /*console.log('io connect');*/ });
		window.socket.on('event',function(data){ /*console.log('io event');*/ });
		window.socket.on('disconnect',function(){ /*console.log('io disconnect');*/ });
		window.socket.on('update',function(e){ /*console.log(e);*/ });
		//window.socket.emit('join', this.props.user.id);

  }

  componentWillUnmount(){

    window.socket.off('connect');
		window.socket.off('event');
		window.socket.off('disconnect');
		window.socket.off('update'); 
		window.socket.disconnect();
		console.log(sessionStorage.getItem('backend')+" DELIGANDO SOCKET");

  }
  
  render() {
  
    return (<Router>
      
        
      	<Route render={ (props) => <Header {...props} /> } />

        <div className="principal">

          <Route exact path="/" component={ListaGames} />

          <Route exact path="/studio/:game/" component={Studio} />
          <Route exact path="/studio/:game/:options" component={Studio} />

          { (window.app.state.dataUser !== undefined) && 
          <>
            {

              (parseInt(window.app.state.dataUser.admin) === 1) && 
              <>

                <Route exact path="/configuracao" component={Configuracao} />
                <Route exact path="/usuarios" component={Usuarios} />
                <Route exact path="/bl_categoria" component={Blockly_categoria} />
                <Route exact path="/bl_secao" component={Blockly_secao} />
                <Route exact path="/bl_blocks" component={Blockly_blocks} />

              </>

            }
             
          </>
          }

          <Route exact path="/help" component={Help} />

        </div>

       


    </Router>);

  }


}

export default Principal;
