import axios from 'axios';


//console.log('ip definido: '+sessionStorage.getItem('backend'));
var api = axios.create({secure:false});

var apis = {
	
	login: (data) => api.post(sessionStorage.getItem('backend')+'/auth/password',data),
	
	games: (data) => api.get(sessionStorage.getItem('backend')+'/game/list',{headers: {session: sessionStorage.getItem('session')}}),

	newGames: (dados) => api.post(sessionStorage.getItem('backend')+'/game',dados,{headers: {session: sessionStorage.getItem('session')}}),
	getGame: (id) => api.get(sessionStorage.getItem('backend')+'/game/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	deleteGame: (id) => api.delete(sessionStorage.getItem('backend')+'/game/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getListToolBox: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie',{headers: {session: sessionStorage.getItem('session')}}),
	getToolBox: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/xmltoolbox/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	getAllJsBlocks: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/blocks',{headers: {session: sessionStorage.getItem('session')}}),

	saveSettings: (id,dados) => api.put(sessionStorage.getItem('backend')+'/game/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),

	updateFile: (id,dados) => api.put(sessionStorage.getItem('backend')+'/game/file/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	updateAsset: (id,dados) => api.put(sessionStorage.getItem('backend')+'/asset/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),

	createFile: (dados) => api.post(sessionStorage.getItem('backend')+'/game/file/',dados,{headers: {session: sessionStorage.getItem('session')}}),

	removeFile: (id) => api.delete(sessionStorage.getItem('backend')+'/game/file/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	upload: (dados) => api.post(sessionStorage.getItem('backend')+'/upload/',dados,{headers: {session: sessionStorage.getItem('session'),'Content-Type': 'multipart/form-data'}}),
	postAsset: (dados) => api.post(sessionStorage.getItem('backend')+'/asset/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	
	getAllAssets: (id) => api.get(sessionStorage.getItem('backend')+'/asset/game/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	removeAsset: (id) => api.delete(sessionStorage.getItem('backend')+'/asset/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	dados_video: (video) => api.get(sessionStorage.getItem('backend')+'/video/dados?id='+video,{headers: {session: sessionStorage.getItem('session')}}),

	getStudioOptions: (id) => api.get(sessionStorage.getItem('backend')+'/studiooptions/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getDefaultstartcode: () => api.get(sessionStorage.getItem('backend')+'/asset/initialcode/',{headers: {session: sessionStorage.getItem('session')}}),

	getAnimations: (id) => api.get(sessionStorage.getItem('backend')+'/asset/animation/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	newAnimation: (dados) => api.post(sessionStorage.getItem('backend')+'/asset/animation/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	
	updateAnimation: (id,dados) => api.put(sessionStorage.getItem('backend')+'/asset/animation/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	
	updateFileImportAsset: (id,dados) => api.put(sessionStorage.getItem('backend')+'/game/file/importAssets/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	removeAnimation: (id) => api.delete(sessionStorage.getItem('backend')+'/asset/animation/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	getUsers: () => api.get(sessionStorage.getItem('backend')+'/user/',{headers: {session: sessionStorage.getItem('session')}}),
	updateUser: (id,dados) => api.put(sessionStorage.getItem('backend')+'/user/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newUser: (dados) => api.post(sessionStorage.getItem('backend')+'/user/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteUser: (id) => api.delete(sessionStorage.getItem('backend')+'/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),
 
	getToolboxcategorie: () => api.get(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/',{headers: {session: sessionStorage.getItem('session')}}),
	updateToolboxcategorie: (id,dados) => api.put(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newToolboxcategorie: (dados) => api.post(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteToolboxcategorie: (id) => api.delete(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getBlocklySection: () => api.get(sessionStorage.getItem('backend')+'/blockly/blocklysection/',{headers: {session: sessionStorage.getItem('session')}}),
	updateBlocklySection: (id,dados) => api.put(sessionStorage.getItem('backend')+'/blockly/blocklysection/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newBlocklySection: (dados) => api.post(sessionStorage.getItem('backend')+'/blockly/blocklysection/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteBlocklySection: (id) => api.delete(sessionStorage.getItem('backend')+'/blockly/blocklysection/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getBlocklyBlock: () => api.get(sessionStorage.getItem('backend')+'/blockly/blocklyblock/',{headers: {session: sessionStorage.getItem('session')}}),
	updateBlocklyBlock: (id,dados) => api.put(sessionStorage.getItem('backend')+'/blockly/blocklyblock/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newBlocklyBlock: (dados) => api.post(sessionStorage.getItem('backend')+'/blockly/blocklyblock/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteBlocklyBlock: (id) => api.delete(sessionStorage.getItem('backend')+'/blockly/blocklyblock/'+id,{headers: {session: sessionStorage.getItem('session')}}),
 
	addAssetScene: (asset,gamefiles) => api.post(sessionStorage.getItem('backend')+'/asset/addscene/',{asset:asset,gamefile:gamefiles},{headers: {session: sessionStorage.getItem('session')}}),

 	verifyAcess: () => api.get(sessionStorage.getItem('backend')+'/auth/verify/',{headers: {session: sessionStorage.getItem('session')}}),

 	 

	salvarSecaoToolboxcategorie: (categoria,sections) => api.put(sessionStorage.getItem('backend')+'/blockly/salvarSecaoToolboxcategorie/',{category:categoria,sections:sections},{headers: {session: sessionStorage.getItem('session')}}),
	salvarSecaoBlocks: (section,blocks) => api.put(sessionStorage.getItem('backend')+'/blockly/salvarSecaoBlocks/',{section:section,blocks:blocks},{headers: {session: sessionStorage.getItem('session')}}),

	//usado na configuracao de seções
	getAllJsBlock: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/block',{headers: {session: sessionStorage.getItem('session')}}),

	//para pegar o helper
	getStudioHelper: (id) => api.get(sessionStorage.getItem('backend')+'/studio/get',{headers: {session: sessionStorage.getItem('session')}}),

	setStudioHelper: (file) => api.post(sessionStorage.getItem('backend')+'/studio/',{file:file},{headers: {session: sessionStorage.getItem('session')}}),

	changeOrderFileList: (file,order) => api.put(sessionStorage.getItem('backend')+'/game/file/'+file,{sequence:order},{headers: {session: sessionStorage.getItem('session')}}),

	//Para alterara sequencia dos blocos
	changeSequenceBlocks: (order) => api.put(sessionStorage.getItem('backend')+'/blockly/savesequence/blocks',{order:order},{headers: {session: sessionStorage.getItem('session')}}),

	//Para alterar a sequencia dos blocos dentro da secao
	changeSequenceBlocksSection: (order,section) => api.put(sessionStorage.getItem('backend')+'/blockly/savesequence/blockssection',{order:order,section:section},{headers: {session: sessionStorage.getItem('session')}}),

	getTreeFolder: (id) => api.get(sessionStorage.getItem('backend')+'/game/folder/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	getFile:(project,file) => api.get(sessionStorage.getItem('backend')+'/game/folder/file/'+project+'?file='+file,{headers:{session:sessionStorage.getItem('session')}}),

	saveFile:(project,file,data) => api.post(sessionStorage.getItem('backend')+'/game/savefile/'+project+'?file='+file,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar um arquivo
	postFile:(project,obj) => api.post(sessionStorage.getItem('backend')+'/game/createfile/'+project,obj,{headers:{session:sessionStorage.getItem('session')}}),

	postFolder:(project,obj) => api.post(sessionStorage.getItem('backend')+'/game/createfolder/'+project,obj,{headers:{session:sessionStorage.getItem('session')}}),

	postRenameFile:(project,obj) => api.post(sessionStorage.getItem('backend')+'/game/renamedirorfile/'+project,obj,{headers:{session:sessionStorage.getItem('session')}}),
	 
	deleteAlgo:(project,obj) => api.post(sessionStorage.getItem('backend')+'/game/deletefile/'+project,obj,{headers:{session:sessionStorage.getItem('session')}}),
	 
	saveFileXml:(project,file,data) => api.post(sessionStorage.getItem('backend')+'/game/savexml/'+project+'?file='+file,data,{headers:{session:sessionStorage.getItem('session')}}),

	getDataExpo1:(user,project) => api.get(sessionStorage.getItem('backend')+'/game/jsonexpo/'+project,{headers:{session:sessionStorage.getItem('session')}}),

	restartExpo:(project) => api.get(sessionStorage.getItem('backend')+'/game/restart/'+project,{headers:{session:sessionStorage.getItem('session')}}),

	buildProject:(project) => api.get(sessionStorage.getItem('backend')+'/game/build/'+project,{headers:{session:sessionStorage.getItem('session')}}),

	getStatus: (project) => api.get(sessionStorage.getItem('backend')+'/game/getstatus/'+project,{headers:{session:sessionStorage.getItem('session')}}),

	getLog: (project) => api.get(sessionStorage.getItem('backend')+'/game/getlog/'+project,{headers:{session:sessionStorage.getItem('session')}}),

	reinstallModules: (project) => api.get(sessionStorage.getItem('backend')+'/game/reeinstalarModules/'+project,{headers:{session:sessionStorage.getItem('session')}}),

	reinstallProject: (project) => api.get(sessionStorage.getItem('backend')+'/game/reeinstalarProjeto/'+project,{headers:{session:sessionStorage.getItem('session')}}),


	 
}

export default apis;