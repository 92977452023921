import React, { Component } from 'react';
 

//import brace from 'brace';
import AceEditor from 'react-ace';
import api from './Api';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';

import 'brace/snippets/html';
import 'brace/ext/language_tools';

class Help extends Component {
  
  constructor(props){

    super(props); 

    this.state = {value:"",carregou:false};

    this.getStudioHelper = this.getStudioHelper.bind(this);
    this.onChangeAceEditor = this.onChangeAceEditor.bind(this);

  }
  componentDidMount(){

  	window.app.loading(false);
    this.getStudioHelper();

  }

  async getStudioHelper(){

    await api.getStudioHelper().then((res) => {

        this.setState({value:res.data,carregou:true});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
    });


  }

 async onChangeAceEditor(value){

    await api.setStudioHelper(value).then((res) => {

        //this.setState({value:res.data,carregou:true});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
    });

  }

  

  render(){ 

  	return(<div className="container">

  		<link rel="stylesheet" href="/css/help.css" type="text/css" media="all" />

  		<div className="col-md-12">

  	 
        
  	 
        <br/><br/>

        <font className="fontPadrao"> O código abaixo será injetado no studio, antes do carregamento do blockly.<br/> </font>
       
        {

          (this.state.carregou === true) && 
          <AceEditor
            mode="javascript"
            ref="aceEditor"
            theme="monokai" 
            name="aceEditor"
            editorProps={{$blockScrolling: true}}
            width="auto"
            height={500}
            onChange={this.onChangeAceEditor}
            showPrintMargin={false} 
            value={this.state.value}
          />

        }
       

        <br/><br/>


  		</div>



   



  	</div>) 

  }


}

export default Help;